import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background: rgb(14, 120, 132);
    background: radial-gradient(circle, rgb(18, 152, 176)  10%, rgb(37, 78, 85) 90%);
  }
`;

export default GlobalStyles;
