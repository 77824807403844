import GlobalStyle from "./globalStyles";
import Home from "./components/Home";
import { styled } from "styled-components";
import ReactGA from "react-ga4";
import { useEffect } from "react";

ReactGA.initialize(process.env.REACT_APP_GA_ID);

export default function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "View Home Page",
    });
  }, []);

  return (
    <Wrapper>
      <GlobalStyle />
      <Home />
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;
